<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
    <el-dialog title="Часто используемые системы координат" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="window">
      <coordinate-system
        size="mini"
        @change-cs="changeCS"
        :multiple="true"
        v-model="localSystems"
      ></coordinate-system>
    </el-dialog>
  </div>
</template>

<script>
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

export default {
  name: 'MapCoordinateSystems',
  components: { CoordinateSystem },
  data: () => {
    return {
      dialogSettingsVisible: false,
      localSystems: []
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  mounted: function () {
    if (this.value && this.value.items && Array.isArray(this.value.items)) {
      this.value.items.forEach((item) => {
        this.localSystems.splice(this.localSystems.length, 0, item)
      }, this)
    }
  },
  methods: {
    changeCS(newCS) {
      this.value.items.splice(0, this.value.items.length)
      newCS.forEach((item) => {
        this.value.items.splice(0, 0, item)
      }, this)
      this.$emit('change', this.value)
    }
  }
}
</script>

<style scoped>
  .window > /deep/ .el-dialog {
    width: 60%;
  }
</style>
